module.exports = [{
      plugin: require('/home/tamas/dev/portfolio-gatsby/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/tamas/dev/portfolio-gatsby/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,400,500"]}},
    },{
      plugin: require('/home/tamas/dev/portfolio-gatsby/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-4069714-6"},
    },{
      plugin: require('/home/tamas/dev/portfolio-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
